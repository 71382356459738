import { apiSagaHandleError } from './common';
import { LOAD_JOB_STATS, LOAD_APPLICATION_STATS, LOAD_CREDIT_REPORT, LOAD_ALL_DEALERS, LOAD_INVOICES, LOAD_DEALER } from '../actions/admin';
import service from 'services';
import { all } from 'redux-saga/effects';

const loadJobStats = apiSagaHandleError(service.getJobStats, LOAD_JOB_STATS);
const loadApplicationStats = apiSagaHandleError(service.getApplicationStats, LOAD_APPLICATION_STATS);
const loadCreditReport = apiSagaHandleError(service.getCreditReport, LOAD_CREDIT_REPORT, { alwaysFetch: true });

const loadAllDealers = apiSagaHandleError(service.getAllDealers, LOAD_ALL_DEALERS);
const loadDealer = apiSagaHandleError(service.getDealer, LOAD_DEALER, { alwaysFetch: true });

const loadInvoices = apiSagaHandleError(service.getInvoices, LOAD_INVOICES);

export default function* () {
	yield all([
		loadJobStats,
		loadApplicationStats,
		loadCreditReport,
		loadAllDealers,
		loadDealer,
		loadInvoices
	]);
}