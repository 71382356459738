import React, { Component } from 'react';
import { Route } from 'react-router';
import { makeLoadable } from 'utils/loadable';
import ScrollToTop from './components/Common/ScrollToTop';
import './App.css';

const LoadableHome = makeLoadable(() => import(/* webpackChunkName: 'home' */ 'routes/Home'));

class App extends Component {
	render() {
		return (
			<ScrollToTop>
				<Route path="/" component={LoadableHome} />
			</ScrollToTop>
		);
	}
}

export default App;
