import moment from 'moment';
import Remarkable from 'remarkable';
import words from 'lodash/words';
import TurndownService from 'turndown';

export const humanizePastDate = date => moment.duration(moment(date).diff(moment())).humanize(true);

export const formatDate = date => moment(date).format('LL');

export const formatShortDate = date => date ? moment(date).format('D MMM YYYY') : '';

const createParser = (offsetHead = 0) => {
	const parser = new Remarkable({
		html: false,
		breaks: false,
		linkify: true
	});

	const safeLinks = md => {
		md.renderer.rules.link_open = function(tokens, idx, /* options */ /* env */) {
			const title = tokens[idx].title ? (' title="' + Remarkable.utils.escapeHtml(Remarkable.utils.replaceEntities(tokens[idx].title)) + '"') : '';
			const target = ' target="_blank" rel="noopener noreferrer"';
			return '<a href="' + Remarkable.utils.escapeHtml(tokens[idx].href) + '"' + title + target + '>';
		};
	};

	const offsetHeadings = offset => md => {
		const { renderer: { rules } } = md;
		rules.heading_open = function(tokens, idx /*, options, env */) {
			return `<h${Math.max(1, Math.min(6, tokens[idx].hLevel + offset))}>`;
		};
		rules.heading_close = function(tokens, idx /*, options, env */) {
			return `</h${Math.max(1, Math.min(6, tokens[idx].hLevel + offset))}>\n`;
		};
	};

	parser.use(safeLinks);
	parser.use(offsetHeadings(offsetHead));

	return parser;
};

let parsers = [];

export const parseMarkdown = (markdown, offsetHeadings = 0) => {
	const parser = parsers[offsetHeadings] || (parsers[offsetHeadings] = createParser(offsetHeadings));

	return parser.render(markdown);
};

export const splitMarkdown = (content, intervals = []) => {
	const paragraphs = (content || '').split('\n').filter(c => !!c);

	let wordCount = 0;
	let intervalIndex = 0;
	let lastBreak = -1;
	let chunks = [];

	for (let i = 0; i < paragraphs.length; ++i) {
		const paragraph = paragraphs[i];

		wordCount += words(paragraph).length;

		if (wordCount >= intervals[intervalIndex]) {
			let chunk = '';
			for (let j = i; j > lastBreak; --j) {
				chunk = paragraphs[j] + '\n' + chunk;
			}
			chunks[intervalIndex] = chunk;

			lastBreak = i;

			wordCount = 0;
			++intervalIndex;

			if (intervalIndex > intervals.length - 1) { break; }
		}
	}

	if (lastBreak !== paragraphs.length - 1) {
		let chunk = '';
		for (let i = lastBreak + 1; i < paragraphs.length; ++i) {
			chunk += paragraphs[i] + '\n';
		}

		chunks[chunks.length] = chunk;
	}

	return chunks;
};

export const isoDateString = date => moment(date).toISOString(true).split('T')[0];

export const formatLocation = ({ city, province } = {}) => {
	if(city || province)
		return `${city ? (city + ', ') : ''}${province}`;
	else
		return null;
};

export const htmlToMarkdown = html => {
	const BULLETS = '•·◦‣⁌⁍';

	const doc = new DOMParser().parseFromString(html, 'text/html');

	let markdown = new TurndownService().turndown(doc.body);

	markdown = markdown
		// replace \r\n with \n
		.replace(/\r\n/gm, '\n')
		// remove spaces between newline characters
		.replace(/\n *(?=\n)/gm, '\n')
		.replace(/\n{3,}/gm, '\n\n');

	markdown = markdown
		// replace bullet characters with asterisks
		.replace(new RegExp(`^(\\s*)[${BULLETS}]\\s+(.+)$`, 'gm'), '$1 * $2')
		// remove extra line breaks between bullets
		.replace(/^( *\*\s+.+)\n\n(?= *\*\s)/gm, '$1\n')
		// remove extra line breaks between numbered list items
		.replace(/^(\d+\.\s+.+)\n\n(?=\d+\.\s)/gm, '$1\n');

	return markdown;
};
