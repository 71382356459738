import PROVINCES from './provinces';

export const ALPHA_3 = 'can';

export const LANGUAGES = [
	['English', 'en'], 
	['Français', 'fr']
];

export { PROVINCES };

export const DEFAULT_LANGUAGE = PROVINCES[0][2];
export const DEFAULT_PROVINCE = PROVINCES[0][1];