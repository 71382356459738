import * as Common from './common';
import * as ca from './ca';
import * as us from './us';

export const COUNTRIES = {
	ca,
	us
};

const Constants = (country = process.env.REACT_APP_COUNTRY) => ({
	...Common,
	...COUNTRIES[country],
	COUNTRY: country
});

export default Constants();