import { all, takeEvery, put, take } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import service from 'services';
import { apiSagaHandleError } from './common';
import { GET_LOCALIZED_STRINGS, SET_LANGUAGE, SET_REGION, LOAD_NAVIGATION } from '../actions/localization';
import { REHYDRATE } from 'redux-persist';
import Constants from 'config/constants';
import 'moment/locale/en-ca';
import 'moment/locale/fr-ca';
import moment from 'moment';
import { getCurrentProvince } from 'utils/geolocation';

const getLocalizedStrings = apiSagaHandleError(service.getLocalizedStrings, GET_LOCALIZED_STRINGS);

function* setGeolocation() {
	const channel = eventChannel(emitter => {
		getCurrentProvince()
			.then(province => emitter({ province }))
			.catch(() => emitter({}));

		return () => emitter({});
	});

	const { province } = yield take(channel);

	if(province) {
		const provinceData = Constants.PROVINCES.find(p => p[1] === province);
		if(provinceData) {
			const language = provinceData[2];

			yield put({ type: SET_REGION, payload: province });
			yield put({ type: SET_LANGUAGE, payload: language });
		}
	}
}

const updateLanguage = language => moment.locale(`${language}-${Constants.COUNTRY}`);

function* validateRehyrated({ key, payload }) {
	if(key === 'localization') {
		const { language, region } = (payload || {});

		if(!Constants.LANGUAGES.find(l => l[1] === language) || !Constants.PROVINCES.find(p => p[1] === region)) {
			yield put({ type: SET_REGION, payload: Constants.DEFAULT_PROVINCE });
			yield put({ type: SET_LANGUAGE, payload: Constants.DEFAULT_LANGUAGE });

			yield setGeolocation();
		}
		else
			updateLanguage(language);
	}
}

const handleRehydrate = takeEvery(REHYDRATE, validateRehyrated);

const handleLanguageChange = takeEvery(SET_LANGUAGE, ({ payload }) => updateLanguage(payload));

const loadNavigation = apiSagaHandleError(service.getNavigation, LOAD_NAVIGATION);

export default function* () {
	yield all([
		getLocalizedStrings,
		handleRehydrate,
		handleLanguageChange,
		loadNavigation
	]);
}