import './polyfill';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import store, { persistor } from './redux';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import registerServiceWorker from './registerServiceWorker';
import { PersistGate } from 'redux-persist/integration/react';
import EmptyState from 'components/Layout/EmptyState';

render(
	<Provider store={store}>
		<PersistGate 
			loading={<EmptyState.Loading />} 
			persistor={persistor}>

			<BrowserRouter>
				<App />
			</BrowserRouter>
		</PersistGate>
	</Provider>, 
	document.getElementById('root')
);
registerServiceWorker();
