import { actionCreator } from 'utils/redux';

export const SET_LANGUAGE = 'localization/SET_LANGUAGE';
export const SET_REGION = 'localization/SET_REGION';

export const GET_LOCALIZED_STRINGS = 'localization/GET_LOCALIZED_STRINGS';

export const LOAD_NAVIGATION = 'localization/LOAD_NAVIGATION';

export const setLanguage = actionCreator(SET_LANGUAGE);
export const setRegion = actionCreator(SET_REGION);

export const getLocalizedStrings = actionCreator(GET_LOCALIZED_STRINGS);

export const loadNavigation = actionCreator(LOAD_NAVIGATION);