import ca from './ca';
import us from './us';

const CONFIGS = {
	ca,
	us
};

const Config = (env = process.env.REACT_APP_ENV, country = process.env.REACT_APP_COUNTRY) => {
	const config = CONFIGS[country];

	return {
		...(config[env] || config['development'])
	};
};

export default Config();
