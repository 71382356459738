import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

class ScrollToTop extends Component {
	componentDidUpdate(prevProps) {
		const { location: { pathname } } = this.props;

		if(prevProps.location.pathname !== pathname)
			window.scrollTo(0, 0);
	}

	render() { 
		return this.props.children;
	}
}

ScrollToTop.propTypes = {
	children: PropTypes.node,

	location: PropTypes.object.isRequired
};
 
export default withRouter(ScrollToTop);