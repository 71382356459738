import STATES from './states';

export const ALPHA_3 = 'usa';

export const LANGUAGES = [
	['English', 'en'], 
	//['Français', 'fr']
];

export const PROVINCES = Object.entries(STATES).map(([key, value]) => ([
	{ 'en': value },
	key,
	'en'
]));

export const DEFAULT_LANGUAGE = PROVINCES[0][2];
export const DEFAULT_PROVINCE = PROVINCES[0][1];