import {
	SET_SCROLLING_ENABLED
} from '../actions/layout';

const initialState = {
	scrolling: true
};

const layout = (state = initialState, action) => {
	switch(action.type) {
		case SET_SCROLLING_ENABLED:
			return {
				...state,

				scrolling: action.payload
			};

		default:
			return state;
	}
};

export default layout;