export const MEDIA_BUCKETS = [
	'cars-n-jobs.s3.amazonaws.com',
	'media-carsandjobs-com.s3.amazonaws.com',
	'media-test-carsandjobs-com.s3.amazonaws.com',
	'media-uscarsandjobs-com.s3.amazonaws.com'
];

export const MEMBEE_API_KEY = 'ba9fae31-e8c4-47ba-971c-4567bd5593eb';
export const MEMBEE_CLIENT_ID = 501;
export const MEMBEE_APP_ID = 2086;

export const DEFAULT_BUTTON_TEXT = 'Read more';
export const DEFAULT_SUBHEADING = 'It\'s about what moves you';
export const DEFAULT_HEADLINE = 'Cars and Jobs';
export const DEFAULT_CTA_LINK = '/';

export const LOAD_COUNT = 25;

export const PUBLIC_URL = window.PUBLIC_URL;

export const DEFAULT_IMAGE_URL = `${PUBLIC_URL}/logo_carsandjobs.png`;
export const DEFAULT_DESCRIPTION = 'Cars and Jobs';

export const HERE_APP_CODE = 'XCklbKmnoCO2akLfBNzvYg';
export const HERE_APP_ID = 'cGqNIsrW7ZTAtmCX1mO0';

export const PAYPAL_CLIENT = {
	dealer: {
		sandbox: 'ATbM6SYQngaYDCZ8rHI1dpGSIsULJNLq1qJhCz037ir1NYc3C_ehTqA2Ya5wMf3Ttc-Qw9LKTW-Q5WLr',
		production: 'AQM_EW6_XWUbFZreWk55Zlm-PFVFAO09hpZEpDkNyjcjkE5LI5Rbeh8LiN49jqSBL3WV7meDYtLCB8Gb'
	},
	tada: {
		sandbox: 'AVE0gzt7GVXAPr3QYZF6Dx1TtfV9hCxMmm1Ok60M0yUgjAg-wY9Tg73kxGqjPdefhYdgljTrNaXZowcU',
		production: 'AeBt8BNQQh-CGy7_YVAa0GKfTfsdouFlajhPOwnJ-lToFlDGGRCpS5E7izaGhkb6-QGDEsxv8ghwWl_O'
	}
};

export const INDEED_CONVERSION_ID = '3353804118991705';

export const POSTING_EDIT_DAYS = 3;

export const REFRESH_TOKEN_INTERVAL = 30 * 60 * 1000;
