import ApiError from './ApiError';

export const fetchApi = async (request, parseError) => {
	let response;
	try {
		response = await fetch(request);
	}
	catch(error) {
		throw new ApiError(error.message);
	}

	if(response.ok)
		return await response.json();
	else {
		let message = null;

		if(parseError)
			try {
				const json = await response.json();
				message = parseError(json);
			}
			/* eslint-disable no-empty */
			catch(error) {}
			/* eslint-enable no-empty */

		throw new ApiError(message, response.status);
	}
};
